import React from "react"
import {gql, useQuery} from "@apollo/client"
import {useAuth} from "./AuthProvider"
import {User} from "./user"
import {GetReportPermissions, GetReportPermissionsVariables} from "./types/GetReportPermissions"

export const UserContext = React.createContext<User | null>(null)

export function useUser(): User {
    const user = useUserIfAvailable()
    if (!user) throw new Error("No user is logged in")
    return user
}

export function useUserIfAvailable(): User | null {
    return React.useContext(UserContext)
}

export const getReportPermissions = gql`
    query GetReportPermissions($user_id: String!) {
        permissions: status_report_permission(where: {user_id: {_eq: $user_id}}) {
            user_id
            report_id
            manager
            sponsor
            statusbericht_vollberechtigte
        }
    }
`

interface Props {
}

export const UserProvider: React.FC<Props> = ({children}) => {
    const userId = useAuth().current()?.email

    const {data} = useQuery<GetReportPermissions, GetReportPermissionsVariables>(getReportPermissions, {
        variables: {user_id: userId!}, skip: !userId,
    })

    const permissions = data?.permissions ?? []
    const getReport = (statusReportId: string) => permissions
        .filter(report => report.user_id === userId)
        .find((report) => report.report_id === statusReportId)

    console.log(`permissions for ${userId}`, {permissions})
    const user: User = {
        isManager(statusReportId: string): boolean {
            return getReport(statusReportId)?.manager ?? false
        },

        isSponsor(statusReportId: string): boolean {
            return getReport(statusReportId)?.sponsor ?? false
        },

        isAllowedToSeeFullStatusReport(statusReportId: string): boolean {
            const report = getReport(statusReportId)
            return (report?.manager || report?.sponsor || report?.statusbericht_vollberechtigte) ?? false
        },
    }

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
