import React from "react"
import styles from "../../ReportStyles.module.scss"
import {ResponsivePie} from "@nivo/pie"
import useMediaQuery from "@mui/material/useMediaQuery"
import {useTranslation} from "react-i18next"
import {GraphDialogProps} from "../PortfolioReportMainContent"
import {ProjectStateData} from "./projectStateData"
import {CenteredMetric} from "./CenteredMetricForPieCharts"
import {ProjectStatusType, ProjectStatusValue} from "../../../../utils/cockpit"

interface Props {
    type: ProjectStatusType
    data: ProjectStateData
    setTableDialogOpen?: (value: GraphDialogProps) => void
    printVersion?: boolean
}

const StatusPieChart: React.FC<Props> = ({type, data, printVersion, setTableDialogOpen}) => {
    const {t} = useTranslation("translations")
    const isXSScreen = useMediaQuery("(max-width: 600px)")
    const mobileSize = {top: 20, right: 80, bottom: 80, left: 80}

    const determineMargins = () => {
        if (isXSScreen) return {top: 20, right: 20, bottom: 20, left: 20}
        if (printVersion) return {top: -40, right: 100, bottom: 30, left: 20}
        else return mobileSize
    }

    const chartData = ["in-order", "minor-difficulties", "major-difficulties"].map((state) => {
        const i18nKey = `portfolio-report-state-${state}`
        return {
            id: state,
            label: `${t(i18nKey)} `,
            value: data.projectStatesWithLatestState(state as ProjectStatusValue).length,
        }
    })

    return (
        <div className={styles.statusPieWrapper}>
            <ResponsivePie
                layers={["arcs", "arcLabels", "legends", CenteredMetric]}
                animate={!printVersion}
                data={chartData}
                margin={determineMargins()}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                theme={{
                    fontSize: 12,
                    labels: {
                        text: {
                            fontWeight: 800,
                            fontSize: 14,
                        },
                    },
                }}
                colors={["#00c851", "#ffbb33", "#ff4444"]}
                tooltip={(item) => {
                    return (
                        <div className={styles.graphToolTip}>
                            <div className={styles.colorBox} style={{backgroundColor: `${item.datum.color}`}}></div>

                            <p>
                                {item.datum.label} {` - `}
                                <b>{item.datum.value}</b>
                            </p>
                        </div>
                    )
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={1}
                arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 3]],
                }}
                onClick={(node) => {
                    setTableDialogOpen && setTableDialogOpen({graphType: "status", filterValue: node.id.toString(), statusType: type})
                }}
            />
            <div className={styles.legend}>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: "#00c851"}}></div>
                    <span>{t(`${chartData[0].id}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: "#ffbb33"}}></div>
                    <span>{t(`${chartData[1].id}`)}</span>
                </div>
                <div className={styles.item}>
                    <div className={styles.colorCircle} style={{backgroundColor: "#ff4444"}}></div>
                    <span>{t(`${chartData[2].id}`)}</span>
                </div>
            </div>
        </div>
    )
}

export default StatusPieChart
