import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {formatDate, formatIsoDate} from "../../../../utils/dates"
import {distinct} from "../../../../utils/arrays"
import {gql, useQuery} from "@apollo/client"
import CircularProgress from "@mui/material/CircularProgress"
import {GetProjectStatusHistory, GetProjectStatusHistoryVariables} from "./types/GetProjectStatusHistory"
import {ProjectStatusType, ProjectStatusValue} from "../../../../utils/cockpit"

const GetProjectStatusHistoryGql = gql`
    query GetProjectStatusHistory($id: uuid!, $max_report_date: date!) {
        status_history: status_report_status_report_by_pk(id: $id) {
            initiative {
                status_reports(where: {_and: [
                    {id: {_neq: $id}}, {state: {_in: ["released"]}}, {report_date: {_lt: $max_report_date}}
                ]}, order_by: {report_date: asc}, limit: 5) {
                    id
                    report_date
                    project_states {
                        state
                        type
                        id
                    }
                }
            }
        }
    }
`

const types: ProjectStatusType[] = ["costs", "resources", "schedule", "scope", "total"]

interface Props {
    statusReportId: string
    reportDate: Date
}

export const ProjectStatusHistory: React.FC<Props> = ({statusReportId, reportDate}) => {
    const {t} = useTranslation("translations")

    const {
        data,
        loading,
        error,
    } = useQuery<GetProjectStatusHistory, GetProjectStatusHistoryVariables>(GetProjectStatusHistoryGql, {
        variables: {id: statusReportId, max_report_date: formatIsoDate(reportDate)},
    })
    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                 id="status-report-status-history-spinner">
                <CircularProgress />
            </div>
        )
    }

    const reports = data.status_history?.initiative?.status_reports ?? []

    const statusIconPicker = (state: ProjectStatusValue) => {
        if (state === "in-order") return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}} />
        if (state === "major-difficulties") return <FontAwesomeIcon icon="exclamation-circle"
                                                                    style={{color: "#ed4337"}} />
        else return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}} />
    }

    const headers = () => {
        return reports.map((status) => formatDate(new Date(status.report_date))).filter(distinct)
    }

    const statusFor = (type: ProjectStatusType) => {
        return reports.map(status => status.project_states
                .find((state) => state.type === type)!.state as ProjectStatusValue)
    }

    return (
        <div className={styles.tableContainer}>
            {reports.length > 1 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {headers().map((header, index) => {
                                    return (
                                        <TableCell key={index} align="center">
                                            {header}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {types.map((name, index) => {
                                return (
                                    <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
                                            {t(name)}
                                        </TableCell>
                                        {statusFor(name).map((state, index) => {
                                            return (
                                                <TableCell key={index} align="center">
                                                    {statusIconPicker(state)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {reports.length <= 1 && (
                <div className={styles.noDataAvailable}>
                    <p style={{marginTop: "30px"}}>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}
