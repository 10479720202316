import React from "react"
import {useAuth} from "./AuthProvider"

export const EnforceLogin: React.FC = ({children}) => {
    const auth = useAuth()
    const user = auth.current()
    // const {t} = useTranslation("authentication")
    if (!user) {
        auth.login()
        // TODO make pretty and german
        return <div>Loading user...</div>
    }
    return <>{children}</>
}
