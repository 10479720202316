import React from "react"
import styles from "../../StatusReport.module.scss"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextField from "@mui/material/TextField"

interface SummaryItemProps {
    name: string
    value: string | null
    editable?: boolean
    onValueChange?: (value: string) => void
    onClose?: () => void
}

const SummaryTextCell: React.FC<SummaryItemProps> = (
    {
        name,
        value,
        editable = false,
        onValueChange = () => {},
        onClose = () => {},
    }) => {
    const [editToggle, setEditToggle] = React.useState<boolean>(false)

    const handleClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEditToggle(false)
        onClose()
    }

    return (
        <div className={styles.reportTextParagraph} onDoubleClick={() => setEditToggle(true)}>
            <div className={styles.reportTextCellTitle}>
                <p>{name}:</p>
                {editable && (
                    <IconButton aria-label="edit" onClick={() => setEditToggle(true)}>
                        <FontAwesomeIcon icon="edit" />
                    </IconButton>
                )}
            </div>

            {editToggle ? (
                <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                    {/* // margin here needed to keep the input at the same positiion as the text before it */}
                    <div style={{marginTop: "10px"}}>
                        <TextField
                            multiline
                            autoFocus
                            fullWidth
                            id="value-textfield"
                            variant="outlined"
                            value={value}
                            onChange={(e) => onValueChange(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && e.shiftKey) {
                                    return //This prevents the submission on just "Enter", allows for new line to be made by first checking if Enter & Shift are pressed.
                                } else if (e.key === "Enter") handleClose(e)
                            }}
                        />
                    </div>
                </ClickAwayListener>
            ) : (
                <p style={{whiteSpace: "pre-wrap"}}>{value}</p>
            )}
        </div>
    )
}

export default SummaryTextCell
