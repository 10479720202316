import React, {useState} from "react"
import styles from "./StatusReport.module.scss"
import ReportOverview from "./components/ReportOverview"
import ReportSummary from "./components/ReportSummary"
import CurrentProjectStatus from "./components/CurrentProjectStatus"
import {Costs, StatusReportTotalCosts} from "./components/Costs"
import {gql, useQuery} from "@apollo/client"
import CircularProgress from "@mui/material/CircularProgress"
import {Problems} from "./components/Problems"
import ReportAttachments from "./components/ReportAttachments"
import {Section} from "./components/Section"
import {useTranslation} from "react-i18next"
import {useUser} from "../../../auth/UserProvider"
import {StatusOfProjects} from "./program/StatusOfProjects"
import {getPageMargins} from "../../../utils/printStyles"
import {ChangeRequests} from "./components/ChangeRequests"
import {Milestones} from "./components/Milestones"
import {Risks} from "./components/Risks"
import {GetReportSummary, GetReportSummary_status_report, GetReportSummaryVariables} from "./types/GetReportSummary"
import {User} from "../../../auth/user"
import {ProjectStatusHistory} from "./components/ProjectStatusHistory"

export const GetReportSummaryGql = gql`
    query GetReportSummary($id: uuid!) {
        status_report: status_report_status_report_summary(
            where: {id: {_eq: $id}}
        ) {
            id
            state
            report_date
            report_title
            portfolio
            project_name
            project_phase
            project_status
            actual_end_date
            actual_start_date
            planned_start_date
            planned_end_date
            description
            next_milestones
            past_successes
            manager: project_manager_user {
                id
                full_name
            }
            attachments {
                filename
                status_report_id
            }
            initiative {
                issue_id
                initiative_type
                deputy_manager
                sponsor
                deputy_sponsor
            }
            history {
                new_state
                timestamp
            }
        }
    }
`

export type StatusReportSummary = GetReportSummary_status_report

interface Props {
    selectedReportId: string
}

export const StatusReport = React.forwardRef<HTMLDivElement, Props>(({selectedReportId}, ref) => {
    const {t} = useTranslation("translations")

    const user = useUser()
    const [totalCosts, setTotalCosts] = useState<StatusReportTotalCosts>({planned: null, actual: null})

    const {data, loading, error, refetch} = useQuery<GetReportSummary, GetReportSummaryVariables>(GetReportSummaryGql, {
        variables: {id: selectedReportId},
    })

    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }

    const report = data.status_report[0]

    const reportDate = new Date(report.report_date!)
    const editable = isReportEditableBy(report, user)
    const fileUploadAllowed = isFileUploadAllowed(report, user)

    const onTotalCostsLoaded = (newCosts: StatusReportTotalCosts) => {
        if (totalCosts.planned !== newCosts.planned || totalCosts.actual !== newCosts.actual) {
            setTotalCosts(newCosts)
        }
    }

    return (
        <div className={styles.report}>
            {/* This line below is for the print-view */}
            <style>{getPageMargins()}</style>
            <div ref={ref}>
                {/* //OVERVIEW */}
                <Section title={t("overview")}>
                    <ReportOverview report={report} costs={totalCosts} queryRefetch={refetch}
                                    editable={editable} />
                </Section>

                {/* //SUMMARY */}
                <Section title={t("summary")}>
                    <ReportSummary report={report} queryRefetch={refetch} editable={editable} />
                </Section>

                {/* //ATTACHMENTS */}
                {(fileUploadAllowed || report.attachments.length > 0) && (
                    <Section title={t("attachments")}>
                        <ReportAttachments report={report} queryRefetch={refetch} editable={fileUploadAllowed} />
                    </Section>
                )}

                {/* //COSTS & RESOURCE */}
                {user.isAllowedToSeeFullStatusReport(report.id!) && (
                    <Section title={t("costs-plancosts-vs-actualcosts")}>
                        <Costs statusReportId={report.id!} editable={editable}
                               onCostsLoaded={onTotalCostsLoaded} />
                    </Section>
                )}

                {/* CURRENT STATUS */}
                <Section title={t("current-status")}>
                    <CurrentProjectStatus statusReportId={report.id!} isReportEditable={editable} />
                </Section>

                {/* STATUS HISTORY*/}
                <Section title={t("status-history")}>
                    <ProjectStatusHistory statusReportId={report.id!} reportDate={reportDate} />
                </Section>

                {/* IN PROGRAM REPORT ONLY - STATUS OF THE INDIVIDUAL PROJECTS*/}
                {report.initiative?.initiative_type === "program" && (
                    <Section title={t("program-report-status-of-individual-projects")}>
                        <StatusOfProjects programmIssueId={report.initiative.issue_id} reportDate={reportDate} />
                    </Section>
                )}

                {/* MILESTONES*/}
                <Section title={t("milestones")}>
                    <Milestones statusReportId={report.id!} editable={editable} />
                </Section>

                {/* RISKS*/}
                {user.isAllowedToSeeFullStatusReport(report.id!) && (
                    <Section title={t("risks")}>
                        <Risks statusReportId={report.id!} editable={editable} />
                    </Section>
                )}

                {/* Problems */}
                {user.isAllowedToSeeFullStatusReport(report.id!) && (
                    <Section title={t("problems")}>
                        <Problems statusReportId={report.id!} editable={editable} />
                    </Section>
                )}

                {/* Change requests */}
                {user.isAllowedToSeeFullStatusReport(report.id!) && (
                    <Section title={t("requests_for_changes")}>
                        <ChangeRequests statusReportId={report.id!} editable={editable} />
                    </Section>
                )}
                <div className={styles.footnotes}>
                    <div>
                        <span>{t("status-report-first-footnote")}</span>
                    </div>
                    <div>
                        <span>{t("status-report-second-footnote")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

function isReportEditableBy(report: {id: string | null, state: string | null}, user: User | null): boolean {
    if (user == null) return false

    if (report.state === "unreleased" || report.state === "rejected") return user.isManager(report.id!)
    if (report.state === "in_review") return user.isSponsor(report.id!)
    return false
}

function isFileUploadAllowed(report: {id: string | null, state: string | null}, user: User | null): boolean {
    if (user == null) return false

    if (report.state === "unreleased" || report.state === "rejected") return user.isManager(report.id!)
    return false
}
