import React from "react"
import styles from "../ReportStyles.module.scss"
import {createStyles, withStyles, makeStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Text from "react-texty"
import "react-texty/styles.css"
import Collapse from "@mui/material/Collapse"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import {formatStringForTranslation} from "../../../utils/strings"
import {formatAmount} from "../../../utils/numbers"
import {formatDate} from "../../../utils/dates"
import {GetRisksByInitiative_initiatives_risks as RiskType} from "./types/GetRisksByInitiative"
import {RiskStatus} from "./riskReportData"

interface Props {
    risks: RiskType[]
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

interface RowProps {
    risk: RiskType
    index: number
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        outerTable: {
            "& tr:last-child td": {
                borderBottom: 0,
            },
        },
        truncatedText: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        innerTableBody: {
            "& tr:last-of-type": {
                borderBotom: 0,
                "& td:nth-child(2)": {
                    borderRadius: "0px 0px 0px 5px",
                },
                "& td:last-child": {
                    borderRadius: "0px 0px 5px 0px",
                },
            },
            "& tr td": {
                backgroundColor: "#fff",
            },
        },
        innerTableHeader: {
            "& th:nth-child(2)": {
                borderRadius: "5px 0px 0px 0px",
            },
            "& th:last-child": {
                borderRadius: "0px 5px 0px 0px",
            },
            "& th": {
                backgroundColor: "#f8f8f8",
            },
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "& .MuiTableCell-head span": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
)(TableRow)

const InnerStyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "& th": {
                color: "#2a2a2a",
                fontSize: "0.8rem",
            },
            "& td": {
                fontSize: "0.8rem",
            },

            "&:last-child td, &:last-child th": {
                border: 0,
            },
            "& .MuiTableCell-head span": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
)(TableRow)

const Risks: React.FC<Props> = ({risks}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const getRisksByStatus = (status: RiskStatus[]) => {
        return risks.filter((risk) => status.indexOf(risk.status as RiskStatus) >= 0)
    }

    const openRisks = getRisksByStatus(["Open", "In Progress"])
    const closedRisks = getRisksByStatus(["Eingetreten", "Nicht eingetreten"])

    const hasCountermeasures = (risk: RiskType) => risk.countermeasures.length > 0

    const Row: React.FC<RowProps> = ({risk, index}) => {
        const [open, setOpen] = React.useState(false)

        return (
            <React.Fragment key={index}>
                <StyledTableRow>
                    <TableCell width="12%">{risk.initiative?.summary ?? ""}</TableCell>
                    <TableCell width="2%">
                        {hasCountermeasures(risk) ? (
                            <IconButton aria-label="expand row" size="small" sx={{padding: "0px"}} onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        ) : (
                            <Tooltip title={t("no-countermeasures-tooltip") || ""}>
                                <ReportGmailerrorredIcon fontSize="small" sx={{color: "rgba(0, 0, 0, 0.54)", marginLeft: "3px"}} />
                            </Tooltip>
                        )}
                    </TableCell>
                    <TableCell align="left" width="16%">
                        {risk.summary}
                    </TableCell>
                    <TableCell align="left" width="10%">
                        {t(`risk-status-${formatStringForTranslation(risk.status)}`)}
                    </TableCell>
                    <TableCell align="right" width="20%">
                        {risk.probability}
                    </TableCell>
                    <TableCell align="right" width="10%">
                        {risk.damage_impact}
                    </TableCell>
                    <TableCell align="right" width="10%">
                        {formatAmount(risk.estimated_costs)}
                    </TableCell>
                    <TableCell align="right" width="10%">
                        {formatDate(risk.next_assessment ? new Date(risk.next_assessment) : null)}
                    </TableCell>
                    <TableCell align="right">{risk.assignee_user?.full_name || ""}</TableCell>
                </StyledTableRow>
                {hasCountermeasures(risk) && (
                    <InnerStyledTableRow style={open ? {backgroundColor: "rgb(237, 237, 237)"} : {backgroundColor: "white"}}>
                        <TableCell style={open ? {padding: "10px"} : {padding: "0px", border: 0}} colSpan={100}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box>
                                    <Table size="small" aria-label="purchases" sx={{tableLayout: "fixed"}}>
                                        <TableHead>
                                            <TableRow className={classes.innerTableHeader}>
                                                <TableCell align="left" width="19%">
                                                    {t("counter-measure")}
                                                </TableCell>
                                                <TableCell width="1%"></TableCell>
                                                <TableCell align="left" width="20%">
                                                    {t("status")}
                                                </TableCell>
                                                <TableCell width="20%"></TableCell>
                                                <TableCell width="10%"></TableCell>
                                                <TableCell align="right" width="10%">
                                                    {t("end_date")}
                                                </TableCell>
                                                <TableCell width="10%"></TableCell>
                                                <TableCell align="right" width="10%">
                                                    {t("assignee")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.innerTableBody}>
                                            {risk.countermeasures.map((measure) => {
                                                return (
                                                    <TableRow key={measure.issue_id}>
                                                        <TableCell align="left">{measure.summary}</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left">
                                                            {t(`risk-countermeasure-status-${formatStringForTranslation(measure.status)}`)}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">
                                                            {measure.end_date && formatDate(new Date(measure.end_date))}
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">{measure.assignee_user?.full_name}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </InnerStyledTableRow>
                )}
            </React.Fragment>
        )
    }

    const RisksTable: React.FC<{risks: RiskType[]}> = ({risks}) => {
        return (
            <div className={styles.tableContainer}>
                {risks.length !== 0 && (
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table
                            size="small"
                            aria-label="simple table"
                            //Reactivating the below style will alter the table columns and the longer text will be truncated again:
                            // style={{tableLayout: "fixed"}}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" width="12%">
                                        {t("project-or-program")}
                                    </TableCell>
                                    <TableCell width="2%"></TableCell>
                                    <TableCell align="left" width="16%">
                                        {t("summary")}
                                    </TableCell>
                                    <TableCell align="left" width="10%">
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.truncatedText} width="20%">
                                        <Text tooltip={t("probability-of-occurrence")}>{t("probability-of-occurrence")}</Text>
                                    </TableCell>
                                    <TableCell align="right" className={classes.truncatedText} width="10%">
                                        <Text tooltip={t("damage-extent")}>{t("damage-extent")}</Text>
                                    </TableCell>
                                    <TableCell align="right" width="10%">
                                        {t("estimated-cost")}
                                    </TableCell>
                                    <TableCell align="right" width="10%">
                                        {t("next-assessment")}
                                    </TableCell>
                                    <TableCell align="right" width="10%">
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.outerTable}>
                                {risks.map((risk, index) => (
                                    <Row risk={risk} index={index} key={risk.issue_id} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {risks.length === 0 && (
                    <div className={styles.noDataAvailable}>
                        <p style={{marginTop: "30px"}}>{t("no-data-available")}</p>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Box sx={{width: "100%", marginTop: "10px"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs value={value} onChange={handleChange} aria-label="risks tabs">
                    <Tab label={t("open-risks")} sx={{fontSize: "0.75rem"}} />
                    {closedRisks.length > 0 && <Tab label={t("closed-risks")} sx={{fontSize: "0.75rem"}} />}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <RisksTable risks={openRisks} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RisksTable risks={closedRisks} />
            </TabPanel>
        </Box>
    )
}

export default Risks
