import {format, formatISO} from "date-fns"
import i18next from "i18next"

const detectActiveLang = () => {
    if (i18next.language === "de") return "de-CH"
    if (i18next.language === "fr") return "fr-CH"
    else return "en-US"
}

export const toDateOrNull = (value: string | null | undefined): Date | null => {
    if (value) return new Date(value)
    return null
}

export const formatDate = (date: Date | undefined | null): string => {
    if (date) {
        return format(date, "dd.MM.yyyy")
    }
    return ""
}

export const formatIsoDate = (date: Date): string => {
    return formatISO(date, {
        representation: "date",
    })
}

export const formatStringDateFromStringOrElse = (date: string | null | undefined, orElse: string) => {
    if (date) return formatStringDateFromString(date)
    return orElse
}

export const formatStringDateFromString = (date: string) => {
    return formatStringDateFromDate(new Date(date))
}

export const formatStringDateFromDate = (date: Date) => {
    return date.toLocaleDateString(detectActiveLang(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
}
