import React from "react"
import styles from "../../StatusReport.module.scss"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextField from "@mui/material/TextField"

interface GridItemProps {
    name: string
    value: string
    editable?: boolean
    onValueChange?: (value: string) => void
    onClose?: () => void
}

const GridItemTextCell: React.FC<GridItemProps> = ({
    name,
    value,
    editable = false,
    onValueChange = () => {},
    onClose = () => {},
}) => {
    const [editToggle, setEditToggle] = React.useState<boolean>(false)

    const handleClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEditToggle(false)
        onClose()
    }

    return (
        <Grid item xs={6} sm={6} md={4}>
            <div className={styles.reportTextCell} onDoubleClick={editable ? () => setEditToggle(true) : undefined}>
                <div className={styles.reportTextCellTitle}>
                    <p>{name}:</p>
                    {editable && (
                        <IconButton aria-label="edit" onClick={editable ? () => setEditToggle(true) : undefined}>
                            <FontAwesomeIcon icon="edit" />
                        </IconButton>
                    )}
                </div>

                {editToggle ? (
                    <ClickAwayListener onClickAway={(e) => handleClose(e)} mouseEvent="onMouseUp" touchEvent="onTouchStart">
                        {/* // margin here needed to keep the input at the same positiion as the text before it */}
                        <div style={{marginTop: "10px"}}>
                            <TextField
                                key={"edit-" + name}
                                autoFocus
                                id="value-textfield"
                                variant="outlined"
                                value={value}
                                onChange={(e) => onValueChange(e.currentTarget.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") handleClose(e)
                                }}
                                inputProps={{
                                    sx: {padding: "0px 10px", fontsize: "1rem"},
                                }}
                            />
                        </div>
                    </ClickAwayListener>
                ) : (
                    <p>{value}</p>
                )}
            </div>
        </Grid>
    )
}

export default GridItemTextCell
