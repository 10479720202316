import React from "react"
import {useTranslation} from "react-i18next"
import styles from "./ProjectList.module.scss"
import Paper from "@mui/material/Paper"
import {createStyles, makeStyles} from "@mui/styles"
import Steckbrief from "./Steckbrief"
import {GetProjectsList_projects} from "./types/GetProjectsList"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons"
import {Box} from "@mui/material"
import {DataGrid, GridColDef, frFR, deDE, enUS} from "@mui/x-data-grid"
import i18next from "i18next"

// When using TypeScript 4.x and above
import type {} from "@mui/x-data-grid/themeAugmentation"
import {formatStringForTranslation} from "../../../utils/strings"
import {GridApiCommunity} from "@mui/x-data-grid/internals"

const useStyles = makeStyles(() =>
    createStyles({
        datagrid: {
            border: "none",
            "@global": {
                ".MuiDataGrid-cell:focus": {
                    outline: 0,
                },
            },
            "@media print": {
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                    width: "100%",
                },
            },
            "& .MuiDataGrid-columnHeader:first-of-type": {
                paddingLeft: "16px",
            },
            "& .MuiDataGrid-columnHeader:last-of-type": {
                paddingRight: "16px",
            },
            "& .MuiDataGrid-columnHeader": {
                borderBottom: "2px solid red",
            },
            "& .MuiDataGrid-row": {
                cursor: "pointer",
                "& .MuiDataGrid-cell": {
                    justifyContent: "flex-end",
                },
                "& .MuiDataGrid-cell:first-of-type": {
                    paddingLeft: "16px",
                    justifyContent: "flex-start",
                },
                "& .MuiDataGrid-cell:last-of-type": {
                    paddingRight: "16px",
                },
            },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
                "&:hover": {
                    background: "#e9e9e9",
                },
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff",
                "&:hover": {
                    background: "#e9e9e9",
                },
            },
        },
    })
)

interface Props {
    projects: GetProjectsList_projects[] | undefined
    apiRef: React.MutableRefObject<GridApiCommunity>
}

const ProjectListTable: React.FC<Props> = ({projects, apiRef}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    const getTranslatedValue = (key: string, value: any) => {
        if (value) {
            return t(`${key}-${formatStringForTranslation(value)}`)
        }
        return ""
    }

    const [clickedProjectForProfileModal, setClickedProjectForProfileModal] = React.useState<GetProjectsList_projects | null>(null)
    const handleOpenSteckbriefModal = (row: GetProjectsList_projects) => setClickedProjectForProfileModal(row)
    const handleCloseSteckbriefModal = () => setClickedProjectForProfileModal(null)

    if (!projects) return <></>

    const columns: GridColDef[] = [
        {
            field: "summary",
            headerName: t("name"),
            flex: 2,
            minWidth: 220,
            sortable: true,
            headerAlign: "left",
        },
        {
            field: "portfolio",
            headerName: t(t("portfolio")),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => (params.value ? params.value.name : ""),
        },
        {
            field: "organisation_unit",
            headerName: t("organization-unit"),
            flex: 1,
            minWidth: 200,
            sortable: true,
            headerAlign: "right",
        },
        {
            field: "status",
            headerName: t("status"),
            flex: 1,
            minWidth: 130,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("status", params.value),
        },
        {
            field: "phase",
            headerName: t("phase"),
            flex: 1,
            minWidth: 130,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("phase", params.value),
        },
        {
            field: "type",
            headerName: t("type"),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("type", params.value),
        },
        {
            field: "category",
            headerName: t(t("category")),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("category", params.value),
        },
        {
            field: "kind",
            headerName: t("kind"),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("kind", params.value),
        },
        {
            field: "cross_section_selections",
            headerName: t("cross-section-selection"),
            flex: 1,
            minWidth: 320,
            sortable: true,
            headerAlign: "right",
            align: "right",
            valueGetter: (params) => {
                return params.row?.cross_section_selections
                    ?.map((selection: string) => {
                        return getTranslatedValue("cross-section", selection)
                    })
                    .join(", ")
            },
        },
        {
            field: "manager",
            headerName: t("project-manager"),
            flex: 2,
            sortable: true,
            minWidth: 200,
            headerAlign: "right",
        },
        {
            field: "execution_type",
            headerName: t("execution-type"),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => getTranslatedValue("execution-type", params.value),
        },
        {
            field: "theme_tags",
            headerName: t("theme-tags"),
            flex: 1,
            minWidth: 110,
            sortable: true,
            headerAlign: "right",
            valueGetter: (params) => params.value.join(", ") ?? "",
        },
    ]

    const findRow = (id: string) => {
        return projects.filter((project) => project.id === id)[0]
    }

    const activeLang = () => {
        if (i18next.language === "de") return deDE
        if (i18next.language === "fr") return frFR
        else return enUS
    }

    return (
        <>
            <Box
                sx={{
                    height: projects.length === 0 ? "calc(100vh - 250px)" : undefined,
                    width: "100%",
                }}
                className={styles.dataGridWrapper}
                component={Paper}
            >
                <DataGrid
                    apiRef={apiRef}
                    rows={projects}
                    columns={columns}
                    hideFooterSelectedRowCount
                    autoHeight={projects.length === 0 ? false : true}
                    className={classes.datagrid}
                    localeText={activeLang().components.MuiDataGrid.defaultProps.localeText}
                    onRowClick={(params) => handleOpenSteckbriefModal(findRow(params.row.id))}
                    pageSizeOptions={[10, 25, 50, 100]}
                    slotProps={{toolbar: {csvOptions: {allColumns: true}}}}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide these columns initially
                                portfolio: false,
                                category: false,
                                execution_type: false,
                                theme_tags: false,
                                status: false,
                            },
                        },
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    minHeight: "200px",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEmptySet}
                                    style={{
                                        fontSize: "5rem",
                                        color: "#a2a2a2",
                                        margin: "170px 0px 30px",
                                    }}
                                />
                                <p
                                    style={{
                                        textAlign: "center",
                                        fontSize: "1.1rem",
                                        color: "#a2a2a2",
                                        fontWeight: "bolder",
                                    }}
                                >
                                    {t("no-data-found-matching-filter")}
                                </p>
                            </div>
                        ),
                    }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                        },
                    }}
                    sx={{
                        "& .MuiDataGrid-row": {
                            "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
                                padding: "0px 20px",
                            },
                        },
                        //TODO: Setting the minWidth on the columns above in GridColDef to 220px on the project leader column fixed the weird extra space seen in the chart in print view.
                        //Needs to be 220px only in print view so as to not mess up the screen set up of the grid columns.
                    }}
                />
            </Box>

            <Steckbrief
                open={!!clickedProjectForProfileModal}
                handleClose={handleCloseSteckbriefModal}
                projectData={clickedProjectForProfileModal}
            />
        </>
    )
}

export default ProjectListTable
