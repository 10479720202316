import React, {useState} from "react"
import styles from "../../StatusReport.module.scss"
import IconButton from "@mui/material/IconButton"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import TextField from "@mui/material/TextField"
import {useTranslation} from "react-i18next"

interface CommentTextCellProps {
    initialValue: string | null
    onChanged: (value: string | null) => void
    onDeleted: () => void
    editable: boolean
}

const CommentTextCell: React.FC<CommentTextCellProps> = ({initialValue, onChanged, onDeleted, editable}) => {
    const [editToggle, setEditToggle] = React.useState<boolean>(false)
    const [comment, setComment] = useState<string>(initialValue ?? "")
    const {t} = useTranslation("translations")

    const onClose = (e: MouseEvent | TouchEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEditToggle(false)
        onChanged(comment)
    }

    const deleteComment = () => {
        setComment("")
        onDeleted()
    }

    return (
        <div className={styles.reportTextParagraph} onDoubleClick={() => setEditToggle(true)}>
            <div className={styles.reportTextCellTitle}>
                <p>{t("status-report-comment-title")}</p>
                {editable && (
                    <>
                        <IconButton aria-label="edit" onClick={() => setEditToggle(true)}>
                            <FontAwesomeIcon icon="edit" />
                        </IconButton>

                        <IconButton aria-label="delete" onClick={deleteComment} disabled={editToggle}>
                            <FontAwesomeIcon icon="trash" />
                        </IconButton>
                    </>
                )}
            </div>

            {editToggle && editable ? (
                <ClickAwayListener onClickAway={(e) => onClose(e)}>
                    {/* // margin here needed to keep the input at the same positiion as the text before it */}
                    <div style={{marginTop: "10px"}}>
                        <TextField
                            multiline
                            autoFocus
                            fullWidth
                            id="value-textfield"
                            variant="outlined"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && e.shiftKey) {
                                    return //This prevents the submission on just "Enter", allows for new line to be made by first checking if Enter & Shift are pressed.
                                } else if (e.key === "Enter") onClose(e)
                            }}
                        />
                    </div>
                </ClickAwayListener>
            ) : (
                <p style={{whiteSpace: "pre-wrap"}}>{comment}</p>
            )}
        </div>
    )
}

export default CommentTextCell
