import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {gql, useMutation, useQuery} from "@apollo/client"
import {UpdateChangeRequestsComment, UpdateChangeRequestsCommentVariables} from "./types/UpdateChangeRequestsComment"
import {ExternalLink} from "./ExternalLink"
import {formatStringForTranslation} from "../../../../utils/strings"
import CircularProgress from "@mui/material/CircularProgress"
import {
    GetStatusReportChangeRequests,
    GetStatusReportChangeRequests_status_report_change_requests,
    GetStatusReportChangeRequestsVariables,
} from "./types/GetStatusReportChangeRequests"

const GetStatusReportChangeRequestsGql = gql`
    query GetStatusReportChangeRequests($status_report_id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $status_report_id) {
            id
            change_requests_comment
            change_requests {
                id
                status_report_id
                status
                url
                description
                assignee: assignee_user {
                    id
                    full_name
                }
                reporter: reporter_user {
                    id
                    full_name
                }
            }
        }
    }
`

const updateChangeRequestsComment = gql`
    mutation UpdateChangeRequestsComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {change_requests_comment: $comment}) {
            id
            change_requests_comment
        }
    }
`

type ChangeRequest = GetStatusReportChangeRequests_status_report_change_requests

interface Props {
    statusReportId: string
    editable: boolean
}

export const ChangeRequests: React.FC<Props> = ({statusReportId, editable}) => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetStatusReportChangeRequests, GetStatusReportChangeRequestsVariables>(GetStatusReportChangeRequestsGql, {
        variables: {status_report_id: statusReportId},
    })

    const [update_change_requests_comment] = useMutation<UpdateChangeRequestsComment, UpdateChangeRequestsCommentVariables>(
        updateChangeRequestsComment
    )

    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                 id="status-report-change-requests-spinner">
                <CircularProgress />
            </div>
        )
    }

    const report = data.status_report

    const updateComment = async (comment: string | null) => {
        await update_change_requests_comment({
            variables: {
                report_id: statusReportId,
                comment: comment,
            },
        })
        await refetch()
    }

    return (
        <>
            <CommentTextCell key={`${statusReportId}-change-requests`}
                initialValue={report?.change_requests_comment ?? ""}
                onDeleted={() => updateComment(null)}
                onChanged={updateComment}
                editable={editable}
            />
            <ChangeRequestsTable changeRequests={report?.change_requests ?? []} />
        </>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {},
        statusTableCell: {
            width: "80px",
        },
        userTableCell: {
            width: "250px",
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    })
)(TableRow)

interface TableProps {
    changeRequests: ChangeRequest[]
}

const ChangeRequestsTable: React.FC<TableProps> = ({changeRequests}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const getChangeRequestsByStatus = (statuses: string[]) => {
        return changeRequests.filter((changeRequest) => statuses.indexOf(changeRequest.status ?? "") >= 0)
    }
    const openChangeRequests = getChangeRequestsByStatus(["New", "In Progress", "In Preparation", "Ready for Approval", "Approved"])
    const closedChangeRequests = getChangeRequestsByStatus(["Resolved", "Rejected"])

    const ChangeRequestsTable: React.FC<TableProps> = ({changeRequests}) => {
        return (
            <div className={styles.tableContainer}>
                {changeRequests.length !== 0 && (
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table size="small" aria-label="simple table" style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.descTableCell}>
                                        {t("short-description")}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.userTableCell}>
                                        {t("reporter")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.userTableCell}>
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...changeRequests].sort(sortChangeRequests).map((changeRequest, index) => (
                                    <StyledTableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell align="left" className={classes.descTableCell}>
                                            <span className={styles.externalLink}>
                                                <ExternalLink name={changeRequest.description ?? ""} url={changeRequest.url} />
                                                <sup>(2)</sup>
                                            </span>
                                        </TableCell>
                                        <TableCell align="left" className={classes.statusTableCell}>
                                            {t(`change-request-status-${formatStringForTranslation(changeRequest.status ?? "")}`)}
                                        </TableCell>
                                        <TableCell align="right" className={classes.userTableCell}>
                                            {changeRequest.reporter?.full_name ?? ""}
                                        </TableCell>
                                        <TableCell align="right" className={classes.userTableCell}>
                                            {changeRequest.assignee?.full_name ?? ""}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {changeRequests.length === 0 && (
                    <div className={styles.noDataAvailable}>
                        <p>{t("no-data-available")}</p>
                    </div>
                )}
            </div>
        )
    }

    function sortChangeRequests(left: ChangeRequest, right: ChangeRequest): number {
        return (left.description ?? "").localeCompare(right.description ?? "")
    }

    return (
        <Box sx={{width: "100%", marginTop: "10px"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="risks tabs">
                    <Tab label={t("open-change-requests")} sx={{fontSize: "0.75rem"}} />
                    <Tab label={t("closed-change-requests")} sx={{fontSize: "0.75rem"}} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ChangeRequestsTable changeRequests={openChangeRequests} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ChangeRequestsTable changeRequests={closedChangeRequests} />
            </TabPanel>
        </Box>
    )
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}
