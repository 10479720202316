import React from "react"
import styles from "./StatusReport.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import PageWrapper from "../../../components/PageWrapper"
import {useReactToPrint} from "react-to-print"
import {useFeedback} from "../../../components/Feedback"
import {useNavigate, useParams} from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import {GetReportSummaryGql, StatusReport} from "./StatusReport"
import StatusReportActions from "./StatusReportActions"
import StatusReportFilters, {GetReportsOfInitiativeGql} from "./StatusReportFilters"
import {ChangeStatusReportState, ChangeStatusReportStateVariables} from "./types/ChangeStatusReportState"
import {StatusReportState} from "../../../types/graphql-global-types"
import CircularProgress from "@mui/material/CircularProgress"
import {GetStatusReportMetadata, GetStatusReportMetadataVariables} from "./types/GetStatusReportMetadata"
import {getReportPermissions} from "../../../auth/UserProvider"
import {isPresent} from "../../../utils/arrays"

const GetStatusReportMetadataGql = gql`
    query GetStatusReportMetadata($id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $id) {
            id
            state
            initiative {
                issue_id
                overview_url
                latestReleasedReport: status_reports(where: {state: {_eq: "released"}}, order_by: {report_date: desc}, limit: 1) {
                    id
                }
            }
        }
    }
`

const ChangeStatusReportStateGql = gql`
    mutation ChangeStatusReportState($new_state: StatusReportState!, $status_report_id: String!) {
        change_status_report_state(new_state: $new_state, status_report_id: $status_report_id) {
            new_state
            status_report_id
        }
    }
`

const StatusReportViewer = () => {
    const {t} = useTranslation("translations")
    const feedback = useFeedback()
    const navigate = useNavigate()
    const {reportId} = useParams()

    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [selectedReport, setSelectedReport] = React.useState<string | null>(reportId ?? null)

    React.useEffect(() => {
        if (!selectedReport) return navigate(`/project-management/project-status-report/`)
        if (selectedReport !== reportId) return navigate(`/project-management/project-status-report/${selectedReport}`)
    }, [selectedReport, reportId, navigate])

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
    })

    const [change_report_state] = useMutation<ChangeStatusReportState, ChangeStatusReportStateVariables>(ChangeStatusReportStateGql)

    const {data, loading, error} = useQuery<GetStatusReportMetadata, GetStatusReportMetadataVariables>(
        GetStatusReportMetadataGql,
        {
            variables: {id: selectedReport!},
            skip: !selectedReport,
        },
    )
    if (error) throw Error(error?.message)
    if (loading) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }

    const changeReportState = async (newState: StatusReportState) => {
        try {
            await change_report_state({
                variables: {
                    new_state: newState,
                    status_report_id: selectedReport!,
                },
                refetchQueries: [
                    GetStatusReportMetadataGql,
                    GetReportsOfInitiativeGql,
                    GetReportSummaryGql,
                    newState === StatusReportState.in_review ? getReportPermissions : null,
                ].filter(isPresent),
                awaitRefetchQueries: true,
            })

            feedback.showSuccess("report-state-change-success", t("report-state-change-success"))
        } catch (err: any) {
            feedback.showError("report-state-change-error", t("report-state-change-error"), err)
        }
    }

    const statusReport = data?.status_report
    const getOverviewUrl = () => statusReport?.initiative?.overview_url ?? ""
    const isLatestReleasedReport = () => statusReport?.initiative?.latestReleasedReport
        .some((latest: any) => statusReport?.id === latest.id) ?? false

    return (
        <PageWrapper headerTitle={t("status-reports")} icon={["far", "file-chart-pie"] as IconProp}>
            <div className={styles.dropdownSection}>
                <div className={styles.actionBar}>
                    <StatusReportFilters selectedReport={statusReport ? {
                        id: statusReport.id,
                        initiativeId: statusReport.initiative!.issue_id,
                    } : null} onSelectedReportChanged={setSelectedReport} />
                    <StatusReportActions
                        statusReport={statusReport ? {
                            id: statusReport.id,
                            state: statusReport.state as StatusReportState,
                        } : null}
                        isLatestReleasedReport={isLatestReleasedReport()}
                        onReportStateChanged={changeReportState} />
                </div>
                <div>
                    {statusReport && (
                        <IconButton onClick={handlePrint}>
                            <FontAwesomeIcon icon={["fas", "print"] as IconProp} size="xs" />
                        </IconButton>
                    )}
                    {statusReport && getOverviewUrl() && (
                        <IconButton href={getOverviewUrl()} target="_blank" style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon={["fas", "external-link-alt"] as IconProp} size="xs" />
                        </IconButton>
                    )}
                </div>
            </div>

            <div className={statusReport ? styles.reportArea : styles.emptyReportArea}>
                {statusReport && (
                    <StatusReport selectedReportId={statusReport.id} ref={printStatusReportRef} />
                )}

                {!statusReport && (
                    <div>
                        <FontAwesomeIcon icon={["far", "bullseye-pointer"]} size="3x" />
                        <p>{t("no-report-displayed-text")}</p>
                    </div>
                )}
            </div>
        </PageWrapper>
    )
}

export default StatusReportViewer
