import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {gql, useQuery, useMutation} from "@apollo/client"
import {GetCurrentStatus, GetCurrentStatusVariables} from "./types/GetCurrentStatus"
import ProjectStatusEditor from "./EditComponents/ProjectStatusEditor"
import {StatusProps} from "./EditComponents/ProjectStatusEditor"
import {UpdateCurrentStatus, UpdateCurrentStatusVariables} from "./types/UpdateCurrentStatus"

const GetCurrentStatusGql = gql`
    query GetCurrentStatus($report_id: uuid) {
        project_states: status_report_project_state(where: {status_report_id: {_eq: $report_id}}, order_by: {type: asc}) {
            type
            state
            id
            comment
            status_report_id
        }
    }
`
const UpdateCurrentStatusGql = gql`
    mutation UpdateCurrentStatus($reportId: uuid, $comment: String, $state: String, $type: String) {
        update_status_report_project_state(
            where: {status_report_id: {_eq: $reportId}, type: {_eq: $type}}
            _set: {comment: $comment, state: $state}
        ) {
            affected_rows
        }
    }
`

interface Props {
    statusReportId: string
    isReportEditable: boolean
}

const CurrentProjectStatus: React.FC<Props> = ({statusReportId, isReportEditable}) => {
    const {t} = useTranslation("translations")
    const {data, loading, error, refetch} = useQuery<GetCurrentStatus, GetCurrentStatusVariables>(GetCurrentStatusGql, {
        variables: {report_id: statusReportId},
    })

    const [update_current_status] = useMutation<UpdateCurrentStatus, UpdateCurrentStatusVariables>(UpdateCurrentStatusGql)

    if (loading || !data) {
        return (
            <div className={styles.noDataAvailable}>
                <p>{t("no-data-available")}</p>
            </div>
        )
    }
    if (error) throw Error(error.message)

    const currentStates = data.project_states

    const updateProjectState = async (statusValues: StatusProps) => {
        await update_current_status({
            variables: {
                reportId: currentStates[0].status_report_id,
                type: statusValues.type,
                state: statusValues.state,
                comment: statusValues.comment,
            },
        })
        await refetch()
    }

    return (
        <div className={styles.tableContainer}>
            {currentStates.length === 0 && <div className={styles.noDataAvailable}>{t("no-data-available")}</div>}

            {currentStates.length > 0 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="center">{t("status")}</TableCell>
                                <TableCell align="left">{t("comments")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentStates.map((row) => (
                                <TableRow key={row.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
                                        {t(row.type)}
                                    </TableCell>
                                    <ProjectStatusEditor state={row} isReportEditable={isReportEditable} handleStateUpdate={updateProjectState} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
}

export default CurrentProjectStatus
