import React from "react"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import deSwissLocale from "date-fns/locale/de"

interface Props {
    selectedDate: Date | null
    setNewDate: (value: Date | null) => void
    minDate: Date | undefined | null
}

const StatusReportDatePicker: React.FC<Props> = ({selectedDate, setNewDate, minDate}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deSwissLocale}>
            <DatePicker
                value={selectedDate}
                onChange={(newValue: Date | null) => {
                    setNewDate(newValue)
                }}
                minDate={minDate ?? undefined}
                views={["year", "month", "day"]}
                slotProps={{
                    textField: {
                        sx: {
                            backgroundColor: "#f8f8f8",
                            marginRight: "20px",
                            maxWidth: "130px",
                        },
                        size: "small",
                        InputProps: {
                            sx: { height: "30px" },
                        },
                        inputProps: {
                            style: {
                                fontSize: "0.9em",
                                padding: "6px 0px 4.5px 14px",
                            },
                        },
                    },
                    openPickerButton: {
                        sx: { svg: { height: "1.1rem", width: "1.1rem" } },
                    },
                }}
            />
        </LocalizationProvider>
    )
}

export default StatusReportDatePicker
