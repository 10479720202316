import React, {useState} from "react"
import SelectDropdown from "../../../components/SelectDropdown"
import StatusReportDatePicker from "./components/StatusReportDatePicker"
import {useTranslation} from "react-i18next"
import {addDays, compareDesc, max} from "date-fns"
import {gql, useMutation, useQuery} from "@apollo/client"
import {formatDate, formatIsoDate} from "../../../utils/dates"
import Grid from "@mui/material/Grid"
import {GetReportSummaryGql} from "./StatusReport"
import {GetInitiativesForStatusReport} from "./types/GetInitiativesForStatusReport"
import {GetReportsOfInitiative, GetReportsOfInitiativeVariables} from "./types/GetReportsOfInitiative"
import {UpdateReportDate, UpdateReportDateVariables} from "./types/UpdateReportDate"
import CircularProgress from "@mui/material/CircularProgress"

const GetInitiativesForStatusReportGql = gql`
    query GetInitiativesForStatusReport {
        initiatives: cockpit_be_initiative(where: {
            status_reports: {permissions: {_or: [
                {manager: {_eq: true}},
                {sponsor: {_eq: true}},
                {statusbericht_teilberechtigte: {_eq: true}},
                {statusbericht_vollberechtigte: {_eq: true}}
            ]}}
        }) {
            issue_id
            summary
        }
    }
`

export const GetReportsOfInitiativeGql = gql`
    query GetReportsOfInitiative($initiativeIssueId: String) {
        status_reports: status_report_status_report(where: {initiative_issue_id: {_eq: $initiativeIssueId}}) {
            id
            state
            report_date
            initiative_issue_id
        }
    }
`

const UpdateReportDateGql = gql`
    mutation UpdateReportDate($report_id: uuid!, $report_date: date!) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {report_date: $report_date}) {
            id
            report_date
        }
    }
`

interface Props {
    selectedReport: {id: string, initiativeId: string} | null
    onSelectedReportChanged: (reportId: string | null) => void
}

const StatusReportFilters: React.FC<Props> = ({selectedReport, onSelectedReportChanged}) => {
    const {t, i18n} = useTranslation("translations")

    const [selectedInitiative, setSelectedInitiative] = useState<string | null>(selectedReport?.initiativeId ?? null)

    const {
        data: initiativesData,
        loading: initiativesLoading,
        error: initiativesError,
    } = useQuery<GetInitiativesForStatusReport>(GetInitiativesForStatusReportGql)

    const {
        data: reportsOfInitiativeData,
        loading: reportsOfInitiativeLoading,
        error: reportsOfInitiativeError,
    } = useQuery<GetReportsOfInitiative, GetReportsOfInitiativeVariables>(GetReportsOfInitiativeGql, {
        variables: {initiativeIssueId: selectedInitiative},
        skip: !selectedInitiative,
    })

    const [update_report_date] = useMutation<UpdateReportDate, UpdateReportDateVariables>(UpdateReportDateGql)

    if (initiativesError) throw Error(initiativesError.message)
    if (reportsOfInitiativeError) throw Error(reportsOfInitiativeError.message)

    if (initiativesLoading || reportsOfInitiativeLoading || !initiativesData) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }

    const reports = reportsOfInitiativeData?.status_reports ?? []
    const getReport = (reportId: string) => reports.find((report) => report.id === reportId)

    const onInitiativeChanged = (initiativeId: string) => {
        setSelectedInitiative(initiativeId)
        onSelectedReportChanged(null)
    }

    const onReportChanged = (reportId: string) => {
        onSelectedReportChanged(reportId)
    }

    const onReportDateChange = async (date: Date | null) => {
        if (!date) return
        await update_report_date({
            variables: {
                report_id: selectedReport?.id!,
                report_date: formatIsoDate(date),
            },
            refetchQueries: [GetReportSummaryGql],
            awaitRefetchQueries: true,
        })
    }

    const reportsDropdown = (): {name: string; id: string}[] => {
        return (
            reports
                .slice()
                .sort((left, right) => compareDesc(new Date(left.report_date), new Date(right.report_date)))
                .map((report) => {
                    if (report.state === "unreleased") {
                        return {name: `${t("unreleased")}`, id: report.id}
                    }
                    return {
                        name: `${formatDate(new Date(report.report_date!))} - ${t(report.state)}`,
                        id: report.id,
                    }
                }) ?? []
        )
    }

    const getReportDate = (reportId: string): Date | null => {
        const report = getReport(reportId)
        if (!report) return null
        return new Date(report.report_date)
    }

    const minDate = () => {
        const dates = reports
            .filter((report) => report.state !== "canceled")
            .filter((report) => report.state !== "unreleased")
            .map((report) => new Date(report.report_date))

        if (dates.length === 0) return null
        return addDays(max(dates), 1)
    }

    const generateOptions = () => {
        const options = initiativesData.initiatives
            .map((initiative) => ({name: initiative.summary!, id: initiative.issue_id!})) ?? []
        return options.sort((left, right) => left.name.localeCompare(right.name, i18n.language, {sensitivity: "base"}))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <SelectDropdown
                        key="select-status-report-initiative"
                        title={t("name")}
                        options={generateOptions()}
                        icon={"project-diagram"}
                        type={"projectId"}
                        handleChange={(event) => onInitiativeChanged(event.target.value)}
                        value={selectedInitiative ?? ""}
                    />
                </Grid>
                <Grid item>
                    <SelectDropdown
                        key="SelectStatusReportVersion"
                        title={t("version")}
                        options={reportsDropdown()}
                        icon={"calendar-alt"}
                        type={"version"}
                        handleChange={(event) => onReportChanged(event.target.value)}
                        value={selectedReport?.id ?? ""}
                        isDisabled={!selectedInitiative}
                    />
                </Grid>
                <Grid item>
                    {selectedReport && getReport(selectedReport.id)?.state === "unreleased" && (
                        <StatusReportDatePicker
                            selectedDate={getReportDate(selectedReport.id)}
                            setNewDate={(date) => onReportDateChange(date)}
                            minDate={minDate()}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default StatusReportFilters
