import {ReactKeycloakProvider} from "@react-keycloak/web"
import React, {useMemo} from "react"
import {Auth} from "./auth"
import {AuthClientError, AuthClientEvent, AuthClientTokens} from "@react-keycloak/core"
import Keycloak, {KeycloakInitOptions} from "keycloak-js"
import {useTranslation} from "react-i18next"
import * as Sentry from "@sentry/react"
import {useFeedback} from "../components/Feedback"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import {useConfig} from "../utils/configProvider";

export const AuthContext = React.createContext<Auth | null>(null)

export function useAuth(): Auth {
    const auth = useAuthIfAvailable()
    if (!auth) throw new Error("Cannot access the AuthContext. Please Ensure you added the AuthProvider higher up in your tree")
    return auth
}

export function useAuthIfAvailable(): Auth | null {
    return React.useContext(AuthContext)
}

const Authenticating: React.FC = () => {
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}} id="auth-provider-spinner">
            <CircularProgress />
        </Box>
    )
}

interface Props {
}

export const AuthProvider: React.FC<Props> = ({ children}) => {
    const feedback = useFeedback()
    const config = useConfig()
    const {t} = useTranslation("authentication")
    const keycloak = useMemo(() => new Keycloak(config.keycloak), [config])
    const auth = useMemo(() => new Auth(keycloak), [keycloak])

    const onEvent = (event: AuthClientEvent, error?: AuthClientError) => {
        let err: Error
        switch (event) {
            case "onInitError":
                if (error) err = new Error(`Keycloak initialization error: ${error?.error} (${error?.error_description})`)
                else err = new Error("Keycloak initialization error")
                feedback.showError("auth-error", t("init-error"), err, {fatal: true})
                break
            case "onAuthError":
                if (error) err = new Error(`Keycloak auth error: ${error?.error} (${error?.error_description})`)
                else err = new Error("Keycloak auth error")
                feedback.showError("auth-error", t("auth-error"), err, {fatal: true})
                break
            case "onAuthRefreshError":
                if (error) err = new Error(`Keycloak auth refresh error: ${error?.error} (${error?.error_description})`)
                else err = new Error("Keycloak auth refresh error")
                console.error(`Refreshing token failed, will log in again  (causes reload)`, err)
                Sentry.startTransaction({name: "relogin (could not refresh token)", op: "auth"}).finish()
                auth.login() // will cause a reload
                break
            case "onTokenExpired":
                console.debug("The token has expired, will refresh")
                Sentry.startTransaction({name: "token expired", op: "auth"}).finish()
                break
            case "onAuthRefreshSuccess":
                console.debug("Authentication token has been refreshed")
                Sentry.startTransaction({name: "token refresh", op: "auth"}).finish()
                break
            case "onAuthSuccess":
                console.debug("Authentication successful")
                Sentry.startTransaction({name: "authenticated", op: "auth"}).finish()
                keycloak.updateToken(5)
                break
            default:
        }
    }
    const onTokens = (tokens: AuthClientTokens) => {
        const authUser = auth.current()
        Sentry.setUser({id: authUser?.id})
    }
    const initOptions: KeycloakInitOptions = {
        checkLoginIframe: true,
        silentCheckSsoFallback: false,
        pkceMethod: "S256",
    }
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={initOptions}
            LoadingComponent={<Authenticating />}
            onEvent={onEvent}
            onTokens={onTokens}
        >
            <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
        </ReactKeycloakProvider>
    )
}
