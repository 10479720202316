import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {differenceInDays, subDays} from "date-fns"
import {formatDate, toDateOrNull} from "../../../../utils/dates"
import {formatStringForTranslation} from "../../../../utils/strings"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {gql, useMutation, useQuery} from "@apollo/client"
import {UpdateMilestonesComment, UpdateMilestonesCommentVariables} from "./types/UpdateMilestonesComment"
import {ExternalLink} from "./ExternalLink"
import CircularProgress from "@mui/material/CircularProgress"
import {
    GetStatusReportMilestones,
    GetStatusReportMilestones_status_report_milestones,
    GetStatusReportMilestonesVariables,
} from "./types/GetStatusReportMilestones"


const GetStatusReportMilestonesGql = gql`
    query GetStatusReportMilestones($status_report_id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $status_report_id) {
            id
            milestones_comment
            milestones {
                id
                status_report_id
                status
                description
                planned_date
                actual_date
                url
            }
        }
    }
`

const updateMilestonesComment = gql`
    mutation UpdateMilestonesComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {milestones_comment: $comment}) {
            id
            milestones_comment
        }
    }
`

type Milestone = GetStatusReportMilestones_status_report_milestones

interface MilestonesProps {
    statusReportId: string
    editable: boolean
}

export const Milestones: React.FC<MilestonesProps> = ({statusReportId, editable}) => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetStatusReportMilestones, GetStatusReportMilestonesVariables>(GetStatusReportMilestonesGql, {
        variables: {status_report_id: statusReportId},
    })

    const [update_milestones_comment] = useMutation<UpdateMilestonesComment, UpdateMilestonesCommentVariables>(updateMilestonesComment)

    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                 id="status-report-milestones-spinner">
                <CircularProgress />
            </div>
        )
    }

    const report = data.status_report

    const updateComment = async (comment: string | null) => {
        await update_milestones_comment({
            variables: {
                report_id: statusReportId,
                comment: comment,
            },
        })
        await refetch()
    }

    return (
        <>
            <CommentTextCell key={`${statusReportId}-milestones`}
                initialValue={report?.milestones_comment ?? ""}
                onDeleted={() => updateComment(null)}
                onChanged={updateComment}
                editable={editable}
            />
            <MilestonesTable milestones={report?.milestones ?? []} />
        </>
    )
}


const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {
            width: "250px",
        },
        statusTableCell: {
            width: "80px",
        },
        endTableCell: {
            width: "180px",
        },
    }),
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    }),
)(TableRow)

interface TableProps {
    milestones: Milestone[]
}

const MilestonesTable: React.FC<TableProps> = ({milestones}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    return (
        <div className={styles.tableContainer}>
            {milestones.length !== 0 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="milestones table" style={{tableLayout: "fixed"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.descTableCell}>
                                    {t("short-description")}
                                </TableCell>
                                <TableCell align="left" className={classes.statusTableCell}>
                                    {t("status")}
                                </TableCell>
                                <TableCell align="right">{t("planned-date")}</TableCell>
                                <TableCell align="right">{t("actual-date")}</TableCell>
                                <TableCell align="right" className={classes.endTableCell}>
                                    {t("variance")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...milestones].sort(sortMilestones).map((milestone) => {
                                const variance = varianceInDays(milestone)
                                return (
                                    <StyledTableRow key={milestone.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell align="left" className={classes.descTableCell}>
                                            <span
                                                style={{marginRight: "10px"}}>{getStatusIcon(milestone.planned_date)}</span>
                                            <span className={styles.externalLink}>
                                                <ExternalLink name={milestone.description ?? ""} url={milestone.url} />
                                                <sup>(2)</sup>
                                            </span>
                                        </TableCell>
                                        <TableCell align="left" className={classes.statusTableCell}>
                                            {t(`milestone-status-${formatStringForTranslation(milestone.status!)}`)}
                                        </TableCell>
                                        <TableCell
                                            align="right">{formatDate(toDateOrNull(milestone.planned_date))}</TableCell>
                                        <TableCell
                                            align="right">{formatDate(toDateOrNull(milestone.actual_date))}</TableCell>
                                        <TableCell align="right" className={classes.endTableCell}>
                                            {variance ? `${variance} ${t("days")}` : ""}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {milestones.length === 0 && (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}

function sortMilestones(left: Milestone, right: Milestone): number {
    let result = 0
    const leftDate = left.actual_date ?? left.planned_date
    const rightDate = right.actual_date ?? right.planned_date

    if (leftDate && rightDate) result = new Date(leftDate).getTime() - new Date(rightDate).getTime()
    if (result === 0 && leftDate) result = -1
    if (result === 0 && rightDate) result = 1
    if (result === 0) result = (left.description ?? "").localeCompare(right.description ?? "")
    return result
}

function varianceInDays(milestone: Milestone) {
    if (milestone.actual_date && milestone.planned_date) {
        return differenceInDays(new Date(milestone.actual_date), new Date(milestone.planned_date))
    }
}

function getStatusIcon(date: Date | string | null) {
    if (!date) {
        return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
    }
    const today = new Date()
    const fiveDaysBeforeDeadline = subDays(new Date(date), 5)
    const dateDeadline = new Date(date)

    if (today >= fiveDaysBeforeDeadline && today < dateDeadline)
        return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}} />
    if (dateDeadline > today) return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}} />
    if (dateDeadline < today) return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
}