import React from "react"
import Button from "@mui/material/Button"
import {useTranslation} from "react-i18next"
import {StatusReportState} from "../../../types/graphql-global-types"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import {DialogActions} from "@mui/material"
import {styled} from "@mui/material/styles"
import {useUser} from "../../../auth/UserProvider"

const ConfirmationDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}))

interface Props {
    statusReport: {id: string, state: StatusReportState} | null
    isLatestReleasedReport: boolean
    onReportStateChanged: (newState: StatusReportState) => void
}

interface ChangeStateButtonProps {
    actionTitle: string
    newState: StatusReportState
    color?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning" | undefined
}

const StatusReportActions: React.FC<Props> = ({statusReport, isLatestReleasedReport, onReportStateChanged}) => {
    const {t} = useTranslation("translations")
    const user = useUser()

    const [openDialog, setOpenDialog] = React.useState(false)
    const handleOpenDialogConfirmation = () => setOpenDialog(true)
    const handleCloseDialogConfirmation = () => setOpenDialog(false)

    const isProjectManager =  statusReport ? user.isManager(statusReport.id) : false
    const isSponsor =  statusReport ? user.isSponsor(statusReport.id) : false

    const ChangeStateButton: React.FC<ChangeStateButtonProps> = ({actionTitle, color, newState}) => {
        return (
            <Button
                size="small"
                variant="contained"
                sx={
                    actionTitle === "ready-to-review"
                        ? {height: "30px", marginRight: "10px", minWidth: "140px", whiteSpace: "nowrap"}
                        : {height: "30px", marginRight: "10px", whiteSpace: "nowrap"}
                }
                color={color}
                onClick={() => {
                    setOpenDialog(false)
                    onReportStateChanged(newState)
                }}
            >
                {t(actionTitle)}
            </Button>
        )
    }

    return (
        <div>
            {(statusReport?.state === "unreleased" || statusReport?.state === "rejected") && isProjectManager && (
                <ChangeStateButton newState={StatusReportState.in_review} actionTitle="ready-to-review" />
            )}
            {statusReport?.state === "in_review" && isSponsor && (
                <div style={{display: "flex", marginLeft: "10px"}}>
                    <ChangeStateButton newState={StatusReportState.released} actionTitle="release" />
                    <ChangeStateButton newState={StatusReportState.rejected} actionTitle="reject" color="error" />
                </div>
            )}
            {isLatestReleasedReport && isSponsor && (
                <>
                    <Button
                        onClick={() => handleOpenDialogConfirmation()}
                        color="error"
                        size="small"
                        variant="contained"
                        sx={{height: "30px", margin: "0px 20px 0px 10px"}}
                    >
                        {t("cancel-report")}
                    </Button>
                    <ConfirmationDialog onClose={handleCloseDialogConfirmation} open={openDialog} sx={{minHeight: "600px"}}>
                        <DialogTitle>{t("cancel-report-confirmation-text")}</DialogTitle>
                        <DialogActions>
                            <ChangeStateButton newState={StatusReportState.canceled} actionTitle="yes" color="error" />
                            <Button
                                size="small"
                                variant="contained"
                                sx={{height: "30px"}}
                                color="inherit"
                                onClick={handleCloseDialogConfirmation}
                            >
                                {t("no")}
                            </Button>
                        </DialogActions>
                    </ConfirmationDialog>
                </>
            )}
        </div>
    )
}

export default StatusReportActions
