import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {format} from "date-fns"
import {formatStringForTranslation} from "../../../../utils/strings"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {gql, useMutation, useQuery} from "@apollo/client"
import {UpdateProblemsComment, UpdateProblemsCommentVariables} from "./types/UpdateProblemsComment"
import {ExternalLink} from "./ExternalLink"
import CircularProgress from "@mui/material/CircularProgress"
import {
    GetStatusReportProblems,
    GetStatusReportProblems_status_report_problems,
    GetStatusReportProblemsVariables,
} from "./types/GetStatusReportProblems"

const GetStatusReportProblemsGql = gql`
    query GetStatusReportProblems($status_report_id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $status_report_id) {
            id
            problems_comment
            problems {
                id
                status_report_id
                name
                status
                assignee: assignee_user {
                    id
                    full_name
                }
                due_date
                url
            }
        }
    }
`

const UpdateProblemsCommentGql = gql`
    mutation UpdateProblemsComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {problems_comment: $comment}) {
            id
            problems_comment
        }
    }
`

type Problem = GetStatusReportProblems_status_report_problems

interface Props {
    statusReportId: string,
    editable: boolean,
}

export const Problems: React.FC<Props> = ({statusReportId, editable}) => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetStatusReportProblems, GetStatusReportProblemsVariables>(GetStatusReportProblemsGql, {
        variables: {status_report_id: statusReportId},
    })

    const [update_problems_comment] = useMutation<UpdateProblemsComment, UpdateProblemsCommentVariables>(UpdateProblemsCommentGql)

    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                 id="status-report-problems-spinner">
                <CircularProgress />
            </div>
        )
    }

    const report = data.status_report

    const updateComment = async (comment: string | null) => {
        await update_problems_comment({
            variables: {
                report_id: statusReportId,
                comment: comment,
            },
        })
        await refetch()
    }

    return (
        <>
            <CommentTextCell key={`${statusReportId}-problems`}
                initialValue={report?.problems_comment ?? ""}
                onDeleted={() => updateComment(null)}
                onChanged={updateComment}
                editable={editable}
            />
            <ProblemsTable problems={report?.problems ?? []} />
        </>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {
            width: "250px",
        },
        assigneeCell: {
            width: "180px",
        },
        statusTableCell: {
            width: "80px",
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    })
)(TableRow)

interface TableProps {
    problems: Problem[]
}

const ProblemsTable: React.FC<TableProps> = ({problems}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    return (
        <>
            {problems.length !== 0 && (
                <div className={styles.tableContainer}>
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table size="small" aria-label="simple table" style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.descTableCell}>
                                        {t("name")}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right">{t("due_date")}</TableCell>
                                    <TableCell align="right" className={classes.assigneeCell}>
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...problems].sort(sortProblems).map((row, index) => {
                                    return (
                                        <StyledTableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                            <TableCell align="left" className={classes.descTableCell}>
                                                <span className={styles.externalLink}>
                                                    <ExternalLink name={row.name ?? ""} url={row.url} />
                                                    <sup>(2)</sup>
                                                </span>
                                            </TableCell>
                                            <TableCell align="left" className={classes.statusTableCell}>
                                                {t(`problem-status-${formatStringForTranslation(row.status ?? "")}`)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.due_date && format(new Date(row.due_date), "dd.MM.yyyy")}
                                            </TableCell>
                                            <TableCell align="right" className={classes.assigneeCell}>
                                                {row.assignee?.full_name ?? ""}
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {problems.length === 0 && (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </>
    )
}

function sortProblems(left: Problem, right: Problem): number {
    let result = 0
    if (left.due_date && right.due_date) result = new Date(left.due_date).getTime() - new Date(right.due_date).getTime()
    if (result === 0 && left.due_date) result = -1
    if (result === 0 && right.due_date) result = 1
    if (result === 0) result = (left.name ?? "").localeCompare(right.name ?? "")
    return result
}

