import React, {useEffect} from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import {ResponsiveLine} from "@nivo/line"
import {formatDate} from "../../../../utils/dates"
import {distinct} from "../../../../utils/arrays"
import {ExternalLink} from "./ExternalLink"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {gql, useMutation, useQuery} from "@apollo/client"
import {UpdateCostsComment, UpdateCostsCommentVariables} from "./types/UpdateCostsComment"
import CircularProgress from "@mui/material/CircularProgress"
import {GetReportCosts, GetReportCosts_costs, GetReportCostsVariables} from "./types/GetReportCosts"

const GetReportCostsGql = gql`
    query GetReportCosts($id: uuid!) {
        status_report: status_report_status_report_by_pk(id: $id) {
            id
            costs_comment
        }
        costs: status_report_costs(status_report_id: $id) {
            report_date
            report_date_as_skalar
            total_actual
            total_planned
            url
            actual {
                amount
                date
                date_as_skalar
            }
            planned {
                amount
                date
                date_as_skalar
            }
        }
    }
`

const updateCostsComment = gql`
    mutation UpdateCostsComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {costs_comment: $comment}) {
            id
            costs_comment
        }
    }
`

type ReportCosts = GetReportCosts_costs
export interface StatusReportTotalCosts {
    planned: number | null
    actual: number | null
}

interface Props {
    statusReportId: string
    editable: boolean
    onCostsLoaded: (costs: StatusReportTotalCosts) => void
}

export const Costs: React.FC<Props> = ({statusReportId, editable, onCostsLoaded}) => {
    const {t} = useTranslation("translations")

    const {data, loading, error} = useQuery<GetReportCosts, GetReportCostsVariables>(GetReportCostsGql, {
        variables: {id: statusReportId},
    })
    useEffect(() => {
        if (data?.costs) {
            onCostsLoaded({planned: data.costs.total_planned, actual: data.costs.total_actual})
        }
    }, [data?.costs, onCostsLoaded])

    const [update_costs_comment] = useMutation<UpdateCostsComment, UpdateCostsCommentVariables>(updateCostsComment)

    if (error) throw Error(error.message)
    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-costs-spinner">
                <CircularProgress />
            </div>
        )
    }

    const updateComment = async (newComment: string | null) => {
        await update_costs_comment({
            variables: {
                report_id: statusReportId,
                comment: newComment,
            },
        })
    }

    const costs = data.costs!

    return (
        <>
            <div className={styles.costControlLink}>
                <span className={styles.externalLink}>
                    <ExternalLink name={t("see-cost-control")} url={costs.url} />
                    <sup>(1)</sup>
                </span>
            </div>
            <CommentTextCell key={`${statusReportId}-costs`}
                initialValue={data.status_report?.costs_comment ?? ""}
                onDeleted={() => updateComment(null)}
                onChanged={updateComment}
                editable={editable}
            />
            <CostsGraph costs={costs} />
        </>
    )
}

interface GraphLineTypes {
    id: string
    data: {
        /**
         * Date (as skalar)
         */
        x: number
        /**
         * Amount
         */
        y: number | null
    }[]
}

interface GraphProps {
    costs: ReportCosts
}

const CostsGraph: React.FC<GraphProps> = ({costs}) => {
    const {t} = useTranslation("translations")

    const indexMap = new Map<number, string>()
    const planned = costs.planned.map((planned) => {
        indexMap.set(planned?.date_as_skalar!, formatDate(new Date(planned?.date!)))
        return {x: planned?.date_as_skalar!, y: planned?.amount ?? null}
    })
    const actual = costs.actual.map((actual) => {
        indexMap.set(actual?.date_as_skalar!, formatDate(new Date(actual?.date!)))
        return {x: actual?.date_as_skalar!, y: actual?.amount ?? null}
    })

    const graphData: GraphLineTypes[] = [
        {id: t("actual"), data: actual},
        {id: t("planned"), data: planned},
    ]

    return (
        <>
            {planned.length !== 0 || actual.length !== 0 ? (
                <div style={{height: "400px", marginBottom: "20px"}}>
                    <ResponsiveLine
                        data={graphData}
                        margin={{top: 50, right: 50, bottom: 50, left: 60}}
                        xScale={{
                            type: "linear",
                            min: 0,
                            max:
                                [...actual, ...planned]
                                    .map((entry) => entry.x)
                                    .filter((x) => x % 1 === 0)
                                    .filter(distinct).length + 1,
                        }}
                        xFormat={(value) => `${indexMap.get(Number(value))}`}
                        yScale={{
                            type: "linear",
                            min: 0,
                            max: "auto",
                            stacked: false,
                            reverse: false,
                        }}
                        yFormat=" >-.2f"
                        markers={
                            costs.report_date_as_skalar == null
                                ? []
                                : [
                                    {
                                        axis: "x",
                                        value: costs.report_date_as_skalar,
                                        legend: formatDate(new Date(costs.report_date)),
                                    },
                                ]
                        }
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 40,
                            // legend: "transportation",
                            legendOffset: 36,
                            legendPosition: "middle",
                            format: (value) => `${indexMap.get(value) || ""}`,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: "count",
                            legendOffset: -40,
                            legendPosition: "middle",
                        }}
                        pointSize={10}
                        pointColor={{theme: "background"}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: "serieColor"}}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: "top",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: -30,
                                itemsSpacing: 0,
                                itemDirection: "left-to-right",
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: "circle",
                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                            },
                        ]}
                    />
                </div>
            ) : (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </>
    )
}