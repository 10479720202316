import React from "react"
import styles from "../ReportStyles.module.scss"
import {Trans, useTranslation} from "react-i18next"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import ByCategoryPieChart from "./Charts/ByCategoryPieChart"
import ByTypePieChart from "./Charts/ByTypePieChart"
import ByPhaseBarchart from "./Charts/ByPhaseBarchart"
import ByKindPieChart from "./Charts/ByKindPieChart"
import PortfolioEvaluationPhasePlanned from "./Charts/PortfolioEvaluationPhasePlanned"
import BudgetTable from "./BudgetTable/BudgetTable"
import TotalProjectsByStatus from "./Charts/TotalProjectsByStatus"
import StatusPieChart from "./Charts/StatusPieChart"
import PortfolioReportProjectListTable from "./PortfolioReportProjectListTable"
import {PortfolioReportData} from "./portfolioReportData"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEmptySet} from "@fortawesome/pro-regular-svg-icons"
import GraphProjectsDialog from "./GraphProjectsDialog"
import {ProjectState, statusTypes, toProjectState} from "./Charts/projectStateData"
import ByPriorityPieChart from "./Charts/ByPriorityPieChart"
import {ProjectStatusType} from "../../../utils/cockpit"

interface Props {
    data: PortfolioReportData
}

export interface GraphDialogProps {
    graphType: string
    filterValue: string
    programOrProject?: "program" | "project"
    statusType?: ProjectStatusType
    totalStatusData?: ProjectState[]
}

const PortfolioReportMainContent: React.FC<Props> = ({data}) => {
    const {t} = useTranslation("translations")

    const [tableDialogOpen, setTableDialogOpen] = React.useState<GraphDialogProps | null>(null)

    const handleCloseTableDialog = () => {
        setTableDialogOpen(null)
    }

    return (
        <div className={styles.reportScreenVersion}>
            <Box sx={{flexGrow: 1}} className={`${styles.portfolioReportArea}`}>
                {data.projects().length! > 0 ? (
                    <>
                        {/* Overview Section */}
                        <Grid item container xs={12} spacing={1} className={`${styles.gridContainerSection}`} style={{margin: "5px 0px"}}>
                            <Grid item container xs={12} className={styles.topLineOverview}>
                                <Grid item xs={4} sx={{textAlign: "left"}}>
                                    <div className={styles.reportHeader}>
                                        <h3>{t("portfolio-report-overview-section")} </h3>
                                        <div className={styles.lineDecoration}></div>
                                    </div>
                                </Grid>
                                <Grid item xs={8} className={styles.activeReportsWrapper}>
                                    <p>
                                        <span className={styles.activeReportText}>{t("active-programs")}:</span>
                                        <span className={styles.activeReportNumber}>{data.numberOfPrograms()}</span>
                                    </p>
                                    <p>
                                        <span className={styles.activeReportText}>{t("active-projects")}:</span>
                                        <span className={styles.activeReportNumber}>{data.numberOfProjects()}</span>
                                    </p>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <p className={styles.descriptionText}>{t("overview-description-text")}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{height: "300px", paddingBottom: "30px"}} className={`${styles.gridItem}`}>
                                    <h5>{t("portfolio-report-portfolio-evaluation")}</h5>
                                    <PortfolioEvaluationPhasePlanned data={data} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div style={{height: "320px"}} className={`${styles.gridItem}`}>
                                    <div style={{height: "300px"}}>
                                        <h5>{t("portfolio-report-programs-by-phase")}</h5>
                                        <ByPhaseBarchart
                                            data={data.programs()}
                                            programOrProject="program"
                                            setTableDialogOpen={setTableDialogOpen}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div style={{height: "320px"}} className={`${styles.gridItem}`}>
                                    <div style={{height: "300px"}}>
                                        {" "}
                                        <h5>{t("portfolio-report-projects-by-phase")}</h5>
                                        <ByPhaseBarchart
                                            data={data.projects()}
                                            programOrProject="project"
                                            setTableDialogOpen={setTableDialogOpen}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {/* Portfolio Composition Section */}
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={1}
                            className={`${styles.gridContainerSection} ${styles.portfolioCompSection}`}
                            style={{margin: "10px 0px"}}
                        >
                            <Grid item xs={12} sx={{textAlign: "left"}}>
                                <div className={styles.reportHeader}>
                                    <h3>{t("portfolio-report-portfolio-composition")}</h3>
                                    <div className={styles.lineDecoration}></div>
                                </div>
                                <div className={`${styles.descriptionText} ${styles.fieldDescription}`}>
                                    <Trans i18nKey="link-to-field-descriptions-helpertext">
                                        Infos zu diesen Feldern finden Sie
                                        <a target="_blank" rel="noreferrer" href="https://projekte.be.ch/x/Log7AQ">
                                            hier
                                        </a>
                                        .
                                    </Trans>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={`${styles.descriptionText} ${styles.portfolioCompDescription}`}>
                                    {t("portfolio-composition-text")}
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div className={`${styles.gridItem} ${styles.pieChartContainer}`}>
                                    <h5>{t("portfolio-report-projects-by-type")}</h5>
                                    <div className={styles.pieWrapper}>
                                        <ByTypePieChart data={data} setTableDialogOpen={setTableDialogOpen} />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div className={`${styles.gridItem} ${styles.pieChartContainer}`}>
                                    <h5>{t("portfolio-report-projects-by-category")}</h5>
                                    <div className={styles.pieWrapper}>
                                        <ByCategoryPieChart data={data} setTableDialogOpen={setTableDialogOpen} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div className={`${styles.gridItem} ${styles.pieChartContainer}`}>
                                    <h5>{t("portfolio-report-projects-by-kind")}</h5>
                                    <div className={styles.pieWrapper}>
                                        <ByKindPieChart data={data} setTableDialogOpen={setTableDialogOpen} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div className={`${styles.gridItem} ${styles.pieChartContainer}`}>
                                    <h5>{t("portfolio-report-projects-by-priority")}</h5>
                                    <div className={styles.pieWrapper}>
                                        <ByPriorityPieChart data={data} setTableDialogOpen={setTableDialogOpen} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {/* Budget Section */}
                        <Grid item container spacing={1} className={`${styles.gridContainerSection}`} style={{margin: "10px 0px"}}>
                            <Grid item xs={12} sx={{textAlign: "left"}}>
                                <div className={styles.reportHeader}>
                                    <h3>{t("portfolio-report-budget-section")}</h3>
                                    <div className={styles.lineDecoration}></div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={styles.descriptionText}>{t("budget-table-description-text")}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <BudgetTable data={data} />
                            </Grid>
                        </Grid>

                        {/* Project Status Section */}
                        <Grid item container xs={12} spacing={1} className={`${styles.gridContainerSection}`} style={{margin: "10px 0px"}}>
                            <Grid item xs={12} sx={{textAlign: "left"}}>
                                <div className={styles.reportHeader}>
                                    <h3>{t("project-status")}</h3>
                                    <div className={styles.lineDecoration}></div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={styles.descriptionText}>{t("project-status-description-text")}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{height: "300px"}} className={`${styles.gridItem}`}>
                                    <h5>{t("portfolio-report-project-status-cumulative-bar-chart")}</h5>
                                    <TotalProjectsByStatus
                                        data={toProjectState(data).withType("total")}
                                        setTableDialogOpen={setTableDialogOpen}
                                    />
                                </div>
                            </Grid>
                            {statusTypes.map((type, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={4} key={index}>
                                        <div className={`${styles.gridItem} ${styles.pieChartContainer} `}>
                                            <h5>{t(`portfolio-report-current-status-${type}`)}</h5>
                                            <div className={styles.pieWrapper}>
                                                <StatusPieChart
                                                    type={type}
                                                    data={toProjectState(data).withType(type)}
                                                    setTableDialogOpen={setTableDialogOpen}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <Grid item container spacing={1} className={`${styles.gridContainerSection}`} style={{margin: "10px 0px"}}>
                            <Grid item xs={12} sx={{textAlign: "left"}}>
                                <div className={styles.reportHeader}>
                                    <h3>{t("portfolio-report-portfolio-list")}</h3>
                                    <div className={styles.lineDecoration}></div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={styles.descriptionText}>{t("project-list-description-text")}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <PortfolioReportProjectListTable data={data.initiatives} />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <div>
                        <FontAwesomeIcon icon={faEmptySet} style={{fontSize: "5rem", color: "#a2a2a2", margin: "170px 0px 30px"}} />
                        <p style={{textAlign: "center", fontSize: "1.1rem", color: "#a2a2a2", fontWeight: "bolder"}}>
                            {t("no-data-found-matching-filter")}
                        </p>
                    </div>
                )}

                <GraphProjectsDialog dialogData={tableDialogOpen!} handleCloseTableDialog={handleCloseTableDialog} data={data} />
            </Box>
        </div>
    )
}

export default PortfolioReportMainContent
