import React from "react"
import {useTranslation} from "react-i18next"
import SummaryTextCell from "./EditComponents/SummaryTextCell"
import {gql, useMutation} from "@apollo/client"
import {UpdateReportSummary, UpdateReportSummaryVariables} from "./types/UpdateReportSummary"
import {StatusReportSummary} from "../StatusReport"

const UpdateReportSummaryGql = gql`
    mutation UpdateReportSummary($report_id: uuid!, $next_milestones: String, $past_successes: String, $description: String) {
        update_status_report_status_report_by_pk(
            pk_columns: {id: $report_id}
            _set: {next_milestones: $next_milestones, past_successes: $past_successes, description: $description}
        ) {
            next_milestones
            past_successes
            description
        }
    }
`

interface Props {
    report: StatusReportSummary
    queryRefetch: () => Promise<any>
    editable: boolean
}


const ReportSummary: React.FC<Props> = ({report, queryRefetch, editable}) => {
    const {t} = useTranslation("translations")

    const [nextMilestones, setNextMilestones] = React.useState<string | null>(report.next_milestones)
    const [pastSuccesses, setPastSuccesses] = React.useState<string | null>(report.past_successes)
    const [description, setDescription] = React.useState<string | null>(report.description)

    const [update_report_summary] = useMutation<UpdateReportSummary, UpdateReportSummaryVariables>(UpdateReportSummaryGql)
    const updateReportSummary = async () => {
        await update_report_summary({
            variables: {
                report_id: report.id!,
                next_milestones: nextMilestones,
                past_successes: pastSuccesses,
                description: description,
            },
        })
        await queryRefetch()
    }

    return (
        <>
            <SummaryTextCell
                name={t("short-overview")}
                value={description}
                editable={editable}
                onValueChange={setDescription}
                onClose={updateReportSummary}
            />
            <SummaryTextCell
                name={t("successes-in-last-month")}
                value={pastSuccesses}
                editable={editable}
                onValueChange={setPastSuccesses}
                onClose={updateReportSummary}
            />
            <SummaryTextCell
                name={t("next-milestones")}
                value={nextMilestones}
                editable={editable}
                onValueChange={setNextMilestones}
                onClose={updateReportSummary}
            />
        </>
    )
}

export default ReportSummary
