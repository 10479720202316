import React from "react"
import styles from "../StatusReport.module.scss"
import {useTranslation} from "react-i18next"
import {formatAmount} from "../../../../utils/numbers"
import Grid from "@mui/material/Grid"
import GridItemTextCell from "./EditComponents/GridItemTextCell"
import GridItemDateCell from "./EditComponents/GridItemDateCell"
import {gql, useMutation} from "@apollo/client"
import {useUser} from "../../../../auth/UserProvider"
import {formatStringForTranslation} from "../../../../utils/strings"
import {StatusReportSummary} from "../StatusReport"
import {UpdateReportTitle, UpdateReportTitleVariables} from "./types/UpdateReportTitle"
import {max} from "date-fns"
import {toDateOrNull} from "../../../../utils/dates"
import {StatusReportTotalCosts} from "./Costs"

const UpdateReportTitleGql = gql`
    mutation UpdateReportTitle($report_id: uuid!, $report_title: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {report_title: $report_title}) {
            report_title
        }
    }
`

interface Props {
    report: StatusReportSummary
    costs: StatusReportTotalCosts
    queryRefetch: () => Promise<any>
    editable: boolean
}

const ReportOverview: React.FC<Props> = ({report, costs, queryRefetch, editable}) => {
    const {t} = useTranslation("translations")
    const user = useUser()

    const [reportTitle, setReportTitle] = React.useState<string>(report.report_title ?? "")

    const [update_report_title] = useMutation<UpdateReportTitle, UpdateReportTitleVariables>(UpdateReportTitleGql)
    const updateReportTitle = async () => {
        await update_report_title({
            variables: {
                report_id: report.id!,
                report_title: reportTitle,
            },
        })
        await queryRefetch()
    }

    const isProgram = report.initiative?.initiative_type === "program"

    // NOTE: Iterating single components over reportData, puts it all in wrong order that is needed from mockup
    return (
        <Grid container spacing={2} className={styles.reportTextFlexWrapper}>
            <GridItemTextCell name={t("project-name")} value={report.project_name ?? "-"} />
            <GridItemTextCell
                name={isProgram ? t("program-report-program-manager") : t("project-manager")}
                value={report.manager?.full_name || "-"}
            />
            <GridItemTextCell name={t("portfolio")} value={report.portfolio ?? "-"} />
            <GridItemTextCell name={t("state")} value={t(report.state!)} />
            <GridItemTextCell
                name={isProgram ? t("program-report-status-in-portfolio") : t("project-status")}
                value={report.project_status ? t(`status-${formatStringForTranslation(report.project_status)}`) : "-"}
            />
            <GridItemTextCell
                name={t("phase")}
                value={report.project_phase ? t(`phase-${formatStringForTranslation(report.project_phase)}`) : "-"}
            />
            <GridItemTextCell
                name={t("report-title")}
                value={reportTitle}
                editable={editable}
                onValueChange={setReportTitle}
                onClose={updateReportTitle}
            />
            <GridItemDateCell name={t("report-date")} value={new Date(report.report_date!)} />
            <GridItemDateCell name={t("release-date")} value={latestStateChangeTo("released", report)} />
            <GridItemDateCell name={t("completion-date")} value={latestStateChangeTo("in_review", report)} />
            {/* <Grid item xs={12} sm={6} md={4}></Grid> */}
            <GridItemDateCell name={t("start-date")} value={toDateOrNull(report.actual_start_date)} />
            <GridItemDateCell name={t("planned-end-date")} value={toDateOrNull(report.planned_end_date)} />
            <GridItemDateCell name={t("actual-end-date")} value={toDateOrNull(report.actual_end_date)} />
            {user.isAllowedToSeeFullStatusReport(report.id!) && (
                <GridItemTextCell name={t("planned-costs")} value={`CHF ${formatAmount(costs.planned)}`} />
            )}
            {user.isAllowedToSeeFullStatusReport(report.id!) && (
                <GridItemTextCell name={t("actual-costs")} value={`CHF ${formatAmount(costs.actual)}`} />
            )}
        </Grid>
    )
}

export default ReportOverview

function latestStateChangeTo(state: string, report: StatusReportSummary){
    const dates = report.history.filter((history) => history.new_state === state).map((state) => new Date(state.timestamp))
    if (dates.length === 0) return null
    return max(dates)
}

